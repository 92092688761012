<template >
  <footer class="footer" v-if="vendor && design && params">
    <div class="container overflow-h">
      <div class="build-bottom columns">
        <div class="column">
          <p
            :key="'branchList' + i"
            :style="'color:'+design.footerColor"
            class="f10 fw-600 ls-1"
            v-for="(item, i) in branch"
          >
            <i class="fa fa-phone f-gray"></i>
            {{ item.displayName}} : {{ item.phone}}
          </p>|
          <p class="info-footer" v-if="branch.length == 1 && branch.length > 0">
            <i class="fa fa-phone"></i>
            {{ vendor.phone }}
            <i class="fa fa-envelope-square"></i>
            {{ vendor.email }}
            <br />
            <i class="fa fa-map-marker"></i>
            {{ vendor.address1 }}
            <span v-if="vendor.address2">{{ vendor.address2 }}</span>
            <br />
            {{ vendor.city.name+', '+vendor.country}}
          </p>
        </div>
        <div class="column footer-logo">
          BUILT BY |
          <i class="icon-mks icon-logo-precompro"></i>
          {{versionApp}}
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
import { mapGetters } from 'vuex';
import reservationTypes from '@/store/types/reservation';
export default {
  props: ['branch'],
  computed: {
    ...mapGetters({
      reservation: reservationTypes.getters.reservationsUser
    })
  },
  methods: {
    getRgb (color) {
      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
      return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      } : null;
    },
    transparentColor (color) {
      const rgb = this.getRgb(color);
      return `rgba(${rgb['r']}, ${rgb['g']}, ${rgb['b']}, 0.9)!important`;
    }
  }
};
</script>
<style lang="scss">
.footer {
  height: 40px;
  &-logo {
    height: 40px;
  }
}
</style>
